define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n				<section class=\"productoptions\">\n					<p class=\"js-product-price product-price\">\n						<span itemprop=\"price\" class=\"price\">\n							";
  if (helper = helpers.price) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.price); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						</span>\n					</p>\n					<div class=\"product-variations\">\n						<div class=\"quantitybox fl\">\n							<label for=\"qty-picker\" class=\"quantity\">";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.product)),stack1 == null || stack1 === false ? stack1 : stack1.quantityText)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</label>\n							<input type=\"text\" name=\"quantity\" class=\"qty-picker\" id=\"qty-picker\" size=\"2\" maxlength=\"2\" value=\"1\" placeholder=\"1\">\n						</div>\n						<div class=\"variation-dropdowns fl\">\n							<form action=\"";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ".html\" method=\"post\" class=\"showHint buying-area\">\n								";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.productVariations), {hash:{},inverse:self.noop,fn:self.programWithDepth(2, program2, data, depth0),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n								<div class=\"bulk-discounts\" data-component=\"updateBulkBuy\" data-componentLoad=\"helper\"></div>\n								<input type=\"hidden\" value=\"";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" name=\"buy\" class=\"buy\">\n								<button class=\"cat-button buynow js-buynow thg-track disabled\" type=\"submit\">";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.product)),stack1 == null || stack1 === false ? stack1 : stack1.buyNow)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</button>\n							</form>\n						</div>\n					</div>\n				</section>\n			";
  return buffer;
  }
function program2(depth0,data,depth1) {
  
  var buffer = "", stack1, helper;
  buffer += "\n									<fieldset class=\"field\">\n										<legend>";
  if (helper = helpers.variationName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.variationName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</legend>\n										<label for=\"opts-";
  if (helper = helpers.variationId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.variationId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"cf\">";
  if (helper = helpers.variationName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.variationName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ":</label>\n										<select name=\"option\" id=\"opts-";
  if (helper = helpers.variationId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.variationId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.first), {hash:{},inverse:self.program(5, program5, data),fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " autocomplete=\"off\">\n											<option value=\"\">";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.props)),stack1 == null || stack1 === false ? stack1 : stack1.product)),stack1 == null || stack1 === false ? stack1 : stack1.pleaseSelectText)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</option>\n											";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.options), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n										</select>\n										<input type=\"hidden\" name=\"variation\" value=\"";
  if (helper = helpers.variationId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.variationId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n									</fieldset>\n								";
  return buffer;
  }
function program3(depth0,data) {
  
  var buffer = "";
  return buffer;
  }

function program5(depth0,data) {
  
  
  return "disabled";
  }

function program7(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n												<option value=\"";
  if (helper = helpers.optionId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.optionId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.selected), {hash:{},inverse:self.noop,fn:self.program(8, program8, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += ">";
  if (helper = helpers.displayText) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.displayText); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</option>\n											";
  return buffer;
  }
function program8(depth0,data) {
  
  
  return "selected";
  }

function program10(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n				<section class=\"productoptions\">\n					<p class=\"js-product-price product-price\">\n						<span itemprop=\"price\" class=\"price\">\n							";
  if (helper = helpers.price) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.price); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						</span>\n					</p>\n					<form action=\"";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ".html\" method=\"post\" class=\"field showHint\">\n						<label for=\"qty-picker\" class=\"quantity\">";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.product)),stack1 == null || stack1 === false ? stack1 : stack1.quantityText)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</label>\n						<input type=\"text\" name=\"quantity\" class=\"qty-picker\" id=\"qty-picker\" size=\"2\" maxlength=\"2\" value=\"1\" placeholder=\"1\">\n						<span class=\"cat-button buynow js-buynow thg-track\">\n							<a class=\"productbuy ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.secureqv), {hash:{},inverse:self.noop,fn:self.program(11, program11, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " js-quickview-buy\" rel=\"nofollow\" href=\"";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ".html?ajax=true&amp;buy=";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.product)),stack1 == null || stack1 === false ? stack1 : stack1.buyNow)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</a>\n						</span>\n					</form>\n				</section>\n			";
  return buffer;
  }
function program11(depth0,data) {
  
  
  return "secureqv ";
  }

  buffer += "<section class=\"js-product-quick-view product-quick-view\">\n    <span class=\"js-enhanced-ecommerce-data hidden\"\n          data-product-title=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.productTitle), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.productTitle), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"\n          data-product-id=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.id), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.id), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"\n          data-product-category=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.category), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.category), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"\n          data-product-master-product-id=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.isMasterProduct), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.isMasterProduct), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"\n          data-product-is-master-product-id=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.isMasterProduct), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.isMasterProduct), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"\n          data-product-brand=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.brand), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.brand), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"\n          data-product-price=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.price), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.price), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"\n          data-product-position=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.index), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.index), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\n        </span>\n	<h2>";
  if (helper = helpers.productTitle) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productTitle); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h2>\n	<span class=\"close-fb\">\n		<a href=\"#\">";
  stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.ajaxBasket)),stack1 == null || stack1 === false ? stack1 : stack1.titles)),stack1 == null || stack1 === false ? stack1 : stack1.basketClose)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</a>\n	</span>\n\n	<div class=\"product-details line\">\n		<div class=\"unit size-1of2\">\n			<div class=\"main-image\">\n				<img src=\"";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.productImages)),stack1 == null || stack1 === false ? stack1 : stack1.largeproduct)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" alt=\"";
  if (helper = helpers.productTitle) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productTitle); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"/>\n			</div>\n		</div>\n\n		<div class=\"unit size-1of2\">\n			";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.productVariations), {hash:{},inverse:self.program(10, program10, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n			<section class=\"product-description\">\n					";
  if (helper = helpers.productDescription) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productDescription); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " <a href=\"";
  if (helper = helpers.siteLink) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.siteLink); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  if (helper = helpers.productLink) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productLink); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" class=\"description-view-more\">";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.quickview)),stack1 == null || stack1 === false ? stack1 : stack1.more)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<span class=\"description-ellipses\">...</span></a>\n			</section>\n		</div>\n	</div>\n</section>\n";
  return buffer;
  }); });